.register-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    text-align: center;
    & div{
        margin: 1em;
    }
}

.input-err{
    color: red;
}