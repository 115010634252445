.product-card-container {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 1em;
}

.product-card {
   background-color: #fff;
   border-radius: 25px;
   width: 14.7rem;
   margin: 1em;
   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
   padding: 10px;
   display: flex;
   flex-direction: column;
}

.product-card img {
   max-width: 100%;
   height: auto;
   border-radius: 20px;
   object-fit: cover;
}

.product-card-content {
   color: black;
   padding: 0.4em;
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.product-card-title {
   font-size: 14px;
   font-weight: bold;
}

.sku_marca {
   font-size: 12px;
   font-weight: lighter;
}

.product-card-price {
   color: #facd03;
   font-weight: 500;
   font-size: 1.3em;
}

.iva_color {
   color: black;
   font-weight: 500;
   font-size: 10px;
}

.product-card-button {
   background-color: #facd03;
   color: black;
   padding: 10px; 
   border-radius: 20px;
   border: none;
   font-weight: 700;
   font-size: 10px;
   align-self: center;
   margin: 1em;
}
.btn-agregar {
   display: flex;
   justify-content: center;
   align-items: flex-end;
}

.product-card:hover {
   box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1);
}
