.productView-block{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2em;
    flex-wrap: wrap;
}

.productView-info{
    width: 500px;
    margin: 1em;
}

.productView{
    width: 500px;
    height: 500px;
    margin: 1em;
    padding: 1em;
    display: flex;
    border-radius: 20px;
    box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.75);
}
.product-detail-btn{
    background-color: #facd03;
    color: black;
    padding: 15px; 
    border-radius: 20px;
    border: none;
    font-weight: 700;
    font-size: 25px;
    align-self: center;
    margin: 1em;
}

.img-productView{
    object-fit:contain;   
}