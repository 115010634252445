.section_img {
   background-image: url(../../img/Rectangle\ 36.png);
   height: 18.75em;
}
.section_products_six {
   background-color: #7a8b99;
   border-radius: 25px;
   width: 75em;
   height: 30em;
}
.btn_six {
   border-radius: 25px;
   background-color: #facd03;
   color: black;
   font-size: 10px;
   width: 98px;
   height: 25px;
   border: none;
}
.section_img_new_line {
   background-image: url(../../img/Sectionnuevalinea.png);
   height: auto;
   margin-top: 2em;
}

.btn_new_line {
   border-radius: 25px;
   background-color: #facd03;
   color: black;
   font-size: 18px;
   width: 196px;
   height: 46px;
   border: none;
}
.section_novedades {
   background-image: url(../../img/Novedades.png);
   height: 34em;
}
.section_condiciones {
   margin-top: 5em;
}
.icons-size {
   color: #facd03;
   font-size: 5em;
}

/* Estilos para pantallas medianas */
@media (max-width: 991px) {
   .section_img {
      height: 20em;
   }
   .section_products_six {
      width: 100%;
      height: auto;
   }
   .section_novedades {
      height: auto;
   }
}

/* Estilos para pantallas pequeñas */
@media (max-width: 767px) {
   .section_img {
      height: 15em;
   }
   .section_products_six {
      width: 100%;
      height: auto;
   }
   .section_novedades {
      height: auto;
   }
   .section_img {
      height: auto;
   }
}
